<template>
  <div>
    <p class="title">冷库参数</p>
    <el-form
      :model="editProjectExtInfo"
      ref="editProjectExtInfo"
      :rules="editProjectExtInfoRules"
      class="demo-ruleForm"
    >
      <el-row>
        <el-col :span="18">
          <el-form-item
            label="冷库形状是否规则"
            label-width="150px"
            prop="stdshapeflag"
          >
            <el-radio-group
              v-model="editProjectExtInfo.stdshapeflag"
              size="medium"
            >
              <el-radio-button label="1">是</el-radio-button>
              <el-radio-button label="0">否</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="18">
          <el-form-item label="长宽高" label-width="150px" prop="">
            <el-col :span="6"
              ><el-form-item label="" label-width="10px" prop="length">
                <el-input v-model="editProjectExtInfo.length">
                  <template #append>m</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="" label-width="10px" prop="width"
                ><el-input v-model="editProjectExtInfo.width">
                  <template #append>m</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6"
              ><el-form-item label="" label-width="10px" prop="height">
                <el-input v-model="editProjectExtInfo.height">
                  <template #append>m</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="体积" label-width="150px" prop="volume">
            <el-input v-model="editProjectExtInfo.volume" disabled="true">
              <template #append>m<sup>3</sup></template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="主风机参数" label-width="150px" prop="">
            <el-row>
              <el-form-item label="数量" label-width="150px" prop="deviceqty">
                <el-input v-model="editProjectExtInfo.deviceqty">
                  <template #append></template>
                </el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="原始温度设定" label-width="150px" prop="">
                <el-col :span="10">
                  <el-form-item
                    label="最低温度"
                    label-width="100px"
                    prop="devicetemperminorgset"
                  >
                    <el-input
                      v-model="editProjectExtInfo.devicetemperminorgset"
                    >
                      <template #append>℃</template>
                    </el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="10">
                  <el-form-item
                    label="最高温度"
                    label-width="100px"
                    prop="devicetempermaxorgset"
                  >
                    <el-input
                      v-model="editProjectExtInfo.devicetempermaxorgset"
                    >
                      <template #append>℃</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-row>
            <!---->
            <el-row>
              <el-form-item label="调整后设定温度" label-width="150px" prop="">
                <el-col :span="10">
                  <el-form-item
                    label="最低温度"
                    label-width="100px"
                    prop="devicetemperminfinalset"
                  >
                    <el-input
                      v-model="editProjectExtInfo.devicetemperminfinalset"
                    >
                      <template #append>℃</template>
                    </el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="10">
                  <el-form-item
                    label="最高温度"
                    label-width="100px"
                    prop="devicetempermaxfinalset"
                  >
                    <el-input
                      v-model="editProjectExtInfo.devicetempermaxfinalset"
                    >
                      <template #append>℃</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item
            label="备用风机数"
            label-width="150px"
            prop="devicebackupqty"
          >
            <el-input v-model="editProjectExtInfo.devicebackupqty"
              ><template #append>个</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item
            label="出入口数"
            label-width="150px"
            prop="entryexitqty"
          >
            <el-input v-model="editProjectExtInfo.entryexitqty"
              ><template #append>个</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item
            label="有无风帘"
            label-width="150px"
            prop="windclothflag"
          >
            <el-radio-group
              v-model="editProjectExtInfo.windclothflag"
              size="medium"
            >
              <el-radio-button label="1">有</el-radio-button>
              <el-radio-button label="0">无</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item
            label="有无门帘"
            label-width="150px"
            prop="doorcurtainflag"
          >
            <el-radio-group
              v-model="editProjectExtInfo.doorcurtainflag"
              size="medium"
            >
              <el-radio-button label="1">有</el-radio-button>
              <el-radio-button label="0">无</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="货架数量" label-width="150px" prop="shelfqty">
            <el-input v-model="editProjectExtInfo.shelfqty"
              ><template #append>个</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item
            label="温湿度监测系统探头"
            label-width="150px"
            prop="humiditymonitorflag"
          >
            <el-radio-group
              v-model="editProjectExtInfo.humiditymonitorflag"
              size="medium"
            >
              <el-radio-button label="1">有</el-radio-button>
              <el-radio-button label="0">无</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item
            label="监控系统探头数量"
            label-width="150px"
            prop="humiditymonitorqty"
          >
            <el-input v-model="editProjectExtInfo.humiditymonitorqty"
              ><template #append>个</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item
            label="厂家"
            label-width="150px"
            prop="manufacturer"
          >
            <el-input v-model="editProjectExtInfo.manufacturer">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="验证对象温度控制范围目标"
            label-width="150px"
            prop=""
          >
            <el-col :span="10">
              <el-form-item
                label="最低温度"
                label-width="100px"
                prop="verifytemperaturemin"
              >
                <el-input v-model="editProjectExtInfo.verifytemperaturemin">
                  <template #append>℃</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="10">
              <el-form-item
                label="最高温度"
                label-width="100px"
                prop="verifytemperaturemax"
              >
                <el-input v-model="editProjectExtInfo.verifytemperaturemax">
                  <template #append>℃</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item
            label="冷库外部环境"
            label-width="150px"
            prop="outenvtype"
          >
            <el-radio-group
              v-model="editProjectExtInfo.outenvtype"
              size="medium"
            >
              <el-radio-button label="0">阴凉库</el-radio-button>
              <el-radio-button label="1">大气环境</el-radio-button>
              <el-radio-button label="2">缓冲区</el-radio-button>
              <el-radio-button label="3">常温库</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item
            label="备用发电机组"
            label-width="150px"
            prop="standbygeneratorflag"
          >
            <el-radio-group
              v-model="editProjectExtInfo.standbygeneratorflag"
              size="medium"
            >
              <el-radio-button label="1">有</el-radio-button>
              <el-radio-button label="0">无</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item
            label="是否需要预冷"
            label-width="150px"
            prop="forecoolingflag"
          >
            <el-radio-group
              v-model="editProjectExtInfo.forecoolingflag"
              size="medium"
            >
              <el-radio-button label="1">是</el-radio-button>
              <el-radio-button label="0">否</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- end ext-->
    </el-form>
    <div slot="footer" class="txt-center">
      <el-button type="primary" @click="handler_saveprojectext()"
        >确定</el-button
      >
    </div>
  </div>
</template>

<script>

export default {
  name: "projectext0",
  props: {
    editProjectExtInfo: {
      type: Object,
      required: true,
    },
    
  },
 
  data() {
    return {
      editProjectExtInfoRules: {
        stdshapeflag: [
          { required: true, message: "型状是否规则是必需的", trigger: "blur" },
        ],
        length: [{ required: true, message: "长是必需的", trigger: "blur" }],
        width: [{ required: true, message: "宽是必需的", trigger: "blur" }],
        height: [{ required: true, message: "高是必需的", trigger: "blur" }],
        volume: [{ required: true, message: "体积是必需的", trigger: "blur" }],
        deviceqty: [
          { required: true, message: "设备个数是必需的", trigger: "blur" },
        ],
        devicetemperminorgset: [
          {
            required: true,
            message: "设备原始最低温度设定是必需的",
            trigger: "blur",
          },
        ],
        devicetempermaxorgset: [
          {
            required: true,
            message: "设备原始最高温度设定是必需的",
            trigger: "blur",
          },
        ],
        // devicetemperminfinalset: [
        //   {
        //     required: true,
        //     message: "调整后最低温度设定是必需的",
        //     trigger: "blur",
        //   },
        // ],
        // devicetempermaxfinalset: [
        //   {
        //     required: true,
        //     message: "调整后最高温度设定是必需的",
        //     trigger: "blur",
        //   },
        // ],
        // devicebackupqty: [
        //   { required: true, message: "备用风机数是必需的", trigger: "blur" },
        // ],
        entryexitqty: [
          { required: true, message: "出入口数是必需的", trigger: "blur" },
        ],
        windclothflag: [
          { required: true, message: "有无风帘是必需的", trigger: "blur" },
        ],
        doorcurtainflag: [
          { required: true, message: "有无门帘是必需的", trigger: "blur" },
        ],
        // shelfqty: [
        //   { required: true, message: "货架数量是必需的", trigger: "blur" },
        // ],
        humiditymonitorflag: [
          { required: true, message: "有无温湿度监测系统探头是必需的", trigger: "blur" },
        ],
        // humiditymonitorqty: [
        //   { required: true, message: "监测系统探头数量是必需的", trigger: "blur" },
        // ],
        // manufacturer: [
        //   { required: true, message: "厂家是必需的", trigger: "blur" },
        // ],
        verifytemperaturemin: [
          { required: true, message: "验证对象温度控制范围最低温度是必需的", trigger: "blur" },
        ],
        verifytemperaturemax: [
          { required: true, message: "验证对象温度控制范围最高温度是必需的", trigger: "blur" },
        ],
        outenvtype: [
          { required: true, message: "冷库外部环境是必需的", trigger: "blur" },
        ],
        standbygeneratorflag: [
          { required: true, message: "有无备用发电机组是必需的", trigger: "blur" },
        ],
        forecoolingflag: [
          { required: true, message: "是否需要预冷是必需的", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    editProjectExtInfo: {
      handler(val, oldVal) {
        let length = val.length;
        let width = val.width;
        let height = val.height;
        if (length && width && height) {
          let volume = length * width * height;
          volume = volume.toFixed(2);
          if (volume != this.editProjectExtInfo.volume) {
            this.editProjectExtInfo.volume = volume;
          }
        } else {
          this.editProjectExtInfo.volume = "";
        }
      },
      deep: true, //true 深度监听
    },
  },
  methods: {
    handler_saveprojectext() {
      this.$refs["editProjectExtInfo"].validate((valid) => {
        if (valid) {
          this.$emit('saveprojectext')
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.title{
  text-align: center;
  margin:10px;
}
</style>