<template>
  <div>
    <p class="title">保温箱参数</p>
    <el-form
      :model="editProjectExtInfo"
      ref="editProjectExtInfo"
      :rules="editProjectExtInfoRules"
      class="demo-ruleForm"
    >
      <el-row>
        <el-col :span="18">
          <el-form-item
            label="品牌及型号"
            label-width="150px"
            prop="brand"
          >
           <el-input v-model="editProjectExtInfo.brand">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      
      <el-row>
        <el-col :span="18">
          <el-form-item label="保温箱容积" label-width="150px" prop="volume">
            <el-input v-model="editProjectExtInfo.volume">
              <template #append>升</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="编号" label-width="150px" prop="identifier">
            <el-input v-model="editProjectExtInfo.identifier">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="外部尺寸" label-width="150px" prop="outdimension">
            <el-input v-model="editProjectExtInfo.outdimension">
              <template #append>mm</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="内部尺寸" label-width="150px" prop="innerdimension">
            <el-input v-model="editProjectExtInfo.innerdimension">
              <template #append>mm</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="重量" label-width="150px" prop="weight">
            <el-input v-model="editProjectExtInfo.weight">
              <template #append>kg</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="壁厚" label-width="150px" prop="wallthickness">
            <el-input v-model="editProjectExtInfo.wallthickness">
              <template #append>mm</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="外部材料" label-width="150px" prop="outmaterial">
            <el-input v-model="editProjectExtInfo.outmaterial">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="内部材料" label-width="150px" prop="innermaterial">
            <el-input v-model="editProjectExtInfo.innermaterial">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="蓄冷剂品牌型号" label-width="150px" prop="refrigerantbrand">
            <el-input v-model="editProjectExtInfo.refrigerantbrand">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="蓄冷剂数量" label-width="150px" prop="refrigerantqty">
            <el-input v-model="editProjectExtInfo.refrigerantqty">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="蓄冷剂放置位置" label-width="150px" prop="refrigerantposition">
            <el-input v-model="editProjectExtInfo.refrigerantposition">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="监测系统探头SN号" label-width="150px" prop="monitorsn">
            <el-input v-model="editProjectExtInfo.monitorsn">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="厂家" label-width="150px" prop="manufacturer">
            <el-input v-model="editProjectExtInfo.manufacturer">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="18">
          <el-form-item label="温度监测系统及外部主机安装位置" label-width="150px" prop="temperaturemonitorposition">
            <el-input v-model="editProjectExtInfo.temperaturemonitorposition">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="验证对象温度控制范围目标"
            label-width="150px"
            prop=""
          >
            <el-col :span="10">
              <el-form-item
                label="最低温度"
                label-width="100px"
                prop="verifytemperaturemin"
              >
                <el-input v-model="editProjectExtInfo.verifytemperaturemin">
                  <template #append>℃</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="10">
              <el-form-item
                label="最高温度"
                label-width="100px"
                prop="verifytemperaturemax"
              >
                <el-input v-model="editProjectExtInfo.verifytemperaturemax">
                  <template #append>℃</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="预冷时间"
            label-width="150px"
            prop=""
          >
            <el-col :span="10">
              <el-form-item
                label="开始时间"
                label-width="100px"
                prop="forecoolingstarttime"
              >
                <el-date-picker
                  v-model="editProjectExtInfo.forecoolingstarttime"
                  type="datetime"
                  format="YYYY-MM-DD HH:mm:ss"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  placeholder="开始时间"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="10">
              <el-form-item
                label="结束时间"
                label-width="100px"
                prop="forecoolingendtime"
              >
                <el-date-picker
                  v-model="editProjectExtInfo.forecoolingendtime"
                  type="datetime"
                  format="YYYY-MM-DD HH:mm:ss"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  placeholder="结束时间"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="化霜时间"
            label-width="150px"
            prop=""
          >
            <el-col :span="10">
              <el-form-item
                label="开始时间"
                label-width="100px"
                prop="defroststarttime"
              >
                <el-date-picker
                  v-model="editProjectExtInfo.defroststarttime"
                  type="datetime"
                  format="YYYY-MM-DD HH:mm:ss"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  placeholder="开始时间"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="10">
              <el-form-item
                label="结束时间"
                label-width="100px"
                prop="defrostendtime"
              >
                <el-date-picker
                  v-model="editProjectExtInfo.defrostendtime"
                  type="datetime"
                  format="YYYY-MM-DD HH:mm:ss"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  placeholder="结束时间"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- end ext-->
    </el-form>
    <div slot="footer" class="txt-center">
      <el-button type="primary" @click="handler_saveprojectext()"
        >确定</el-button
      >
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "projectext2",
  props: {
    editProjectExtInfo: {
      type: Object,
      required: true,
    },
    
  },
 
  data() {
    return {
      editProjectExtInfoRules: {
        brand: [
          { required: true, message: "品牌及型号是必需的", trigger: "blur" },
        ],
        
        volume: [{ required: true, message: "容积是必需的", trigger: "blur" }],
        // identifier: [{ required: true, message: "编号是必需的", trigger: "blur" }],
        // outdimension: [
        //   { required: true, message: "外部尺寸是必需的", trigger: "blur" },
        // ],
        innerdimension: [
          { required: true, message: "内部尺寸是必需的", trigger: "blur" },
        ],
        // weight: [
        //   { required: true, message: "重量是必需的", trigger: "blur" },
        // ],
        // wallthickness: [
        //   { required: true, message: "壁厚是必需的", trigger: "blur" },
        // ],
        // outmaterial: [
        //   { required: true, message: "外部材料是必需的", trigger: "blur" },
        // ],
        // innermaterial: [
        //   { required: true, message: "内部材料是必需的", trigger: "blur" },
        // ],
        // refrigerantbrand: [
        //   { required: true, message: "蓄冷剂品牌型号是必需的", trigger: "blur" },
        // ],
        refrigerantqty: [
          { required: true, message: "蓄冷剂数量是必需的", trigger: "blur" },
        ],
        // refrigerantposition: [
        //   { required: true, message: "蓄冷剂放置位置是必需的", trigger: "blur" },
        // ],
        // monitorsn: [
        //   { required: true, message: "监测系统探头SN号是必需的", trigger: "blur" },
        // ],
        // manufacturer: [
        //   { required: true, message: "厂家是必需的", trigger: "blur" },
        // ],
        // temperaturemonitorposition: [
        //   { required: true, message: "温度监测系统及外部主机安装位置是必需的", trigger: "blur" },
        // ],
        verifytemperaturemin: [
          { required: true, message: "验证对象温度控制范围最低温度是必需的", trigger: "blur" },
        ],
        verifytemperaturemax: [
          { required: true, message: "验证对象温度控制范围最高温度是必需的", trigger: "blur" },
        ],
        forecoolingstarttime: [
          { type: "date",required: true, message: "预冷开始时间是必需的", trigger: "blur" },
        ],
        forecoolingendtime: [
          { type: "date",required: true, message: "预冷时间结束时间是必需的", trigger: "blur" },
        ],
        defroststarttime: [
          { type: "date",required: true, message: "化霜开始时间是必需的", trigger: "blur" },
        ],
        defrostendtime: [
          { type: "date",required: true, message: "化霜时间结束时间是必需的", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handler_saveprojectext() {
      this.$refs["editProjectExtInfo"].validate((valid) => {
        if (valid) {

          let forecoolingstarttime=this.editProjectExtInfo.forecoolingstarttime;
          if(forecoolingstarttime){
            let time=moment(forecoolingstarttime).format("YYYY-MM-DD HH:mm:ss");
            this.editProjectExtInfo.forecoolingstarttime=time;
          }
          let forecoolingendtime=this.editProjectExtInfo.forecoolingendtime;
          if(forecoolingendtime){
            let time=moment(forecoolingendtime).format("YYYY-MM-DD HH:mm:ss");
            this.editProjectExtInfo.forecoolingendtime=time;
          }
          let defroststarttime=this.editProjectExtInfo.defroststarttime;
          if(defroststarttime){
            let time=moment(defroststarttime).format("YYYY-MM-DD HH:mm:ss");
            this.editProjectExtInfo.defroststarttime=time;
          }
          let defrostendtime=this.editProjectExtInfo.defrostendtime;
          if(defrostendtime){
            let time=moment(defrostendtime).format("YYYY-MM-DD HH:mm:ss");
            this.editProjectExtInfo.defrostendtime=time;
          }
          this.$emit('saveprojectext')
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.title{
  text-align: center;
  margin:10px;
}
</style>