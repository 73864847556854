<template>
  <div>
    <el-row class="row">
      <el-col :span="24" class="txt-center">{{ computeTitle }}</el-col>
    </el-row>
    <div class="customer-box">
      <el-row class="row" :gutter="20">
        <el-col :span="3" class="txt-right">客户名称</el-col>
        <el-col :span="21" class="txt-left">
          {{ customerInfo?.name }}
          &nbsp;<el-tag style="font-size: 16px;" type="warning"><i class="el-icon-position"></i>&nbsp;{{customerType[customerInfo?.type]}}</el-tag>
        </el-col>
      </el-row>
      <el-row class="row" :gutter="20"
        ><el-col :span="3" class="txt-right">客户标签</el-col>
        <el-col :span="21" class="txt-left">
          <el-tag style="margin-left: 5px;"
                  v-for="(tag, index) in customerCheckedTags"
                  :key="tag.name">
            {{tag.name}}
          </el-tag>
        </el-col></el-row
      >
    </div>

    <div class="tabs-box">
      <el-tabs
        type="border-card"
        v-model="selectedTab"
        @tab-click="handler_tabclick"
      >
        <el-tab-pane label="基本资料" name="first">
          <el-form
            :model="editProjectInfo"
            ref="editProjectInfo"
            :rules="editProjectInfoRules"
            class="demo-ruleForm"
          >
            <el-row>
              <el-col :span="18">
                <el-form-item label="名称" label-width="150px" prop="name">
                  <el-input v-model="editProjectInfo.name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="18">
                <el-form-item label="地点" label-width="150px" prop="address">
<!--                  <el-input v-model="editProjectInfo.address"></el-input>-->
                  <el-select v-model="editProjectInfo.address" filterable placeholder="请选择类型" style="width: 100%">
                    <el-option v-for="item in customerWarehouseList" :key="item.addressfull" :label="item.addressfull"  :value="item.addressfull"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="类型" label-width="150px" prop="type">
                  <el-radio-group v-model="editProjectInfo.type" size="medium">
                    <el-radio-button label="0">冷库</el-radio-button>
                    <el-radio-button label="1">冷藏车</el-radio-button>
                    <el-radio-button label="2">冷藏箱</el-radio-button>
                    <el-radio-button label="3">保温箱</el-radio-button>
                    <el-radio-button label="4">温湿度监控系统</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="法规" label-width="150px" prop="lawstype">
                  <el-radio-group
                    v-model="editProjectInfo.lawstype"
                    size="medium"
                  >
                    <el-radio-button label="0">医疗器械</el-radio-button>
                    <el-radio-button label="1">医药公司</el-radio-button>
                    <el-radio-button label="2">药械公司</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="18">
                <el-form-item label="备注" label-width="150px" prop="memo">
                  <el-input
                    type="textarea"
                    :rows="5"
                    v-model="editProjectInfo.memo"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div slot="footer" class="txt-center">
            <el-button type="primary" @click="handler_saveproject()"
              >确定</el-button
            >
          </div>
        </el-tab-pane>
        <el-tab-pane label="参数详细" name="second" v-if="showTabSecond">
          <div v-if="editProjectInfo.type==0">
            <projectext0 :editProjectExtInfo="editProjectExtInfo" @saveprojectext="handler_saveprojectext"/>
          </div>
          <div v-if="editProjectInfo.type==1">
            <projectext1 :editProjectExtInfo="editProjectExtInfo" @saveprojectext="handler_saveprojectext"/>
          </div>
          <div v-if="editProjectInfo.type==2">
            <projectext2 :editProjectExtInfo="editProjectExtInfo" @saveprojectext="handler_saveprojectext"/>
          </div>
          <div v-if="editProjectInfo.type==3">
            <projectext3 :editProjectExtInfo="editProjectExtInfo" @saveprojectext="handler_saveprojectext"/>
          </div>
          <div v-if="editProjectInfo.type==4">
            <projectext4 :editProjectExtInfo="editProjectExtInfo" @saveprojectext="handler_saveprojectext"/>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>


<script>
import CUST from "../../api/biz/api_customer";
import PROJECT from "../../api/base/api_pm";
import PROJECTEXT from "../../api/biz/api_projectext";
import COMM from "@/api/base/api_comm";
import WAREHOUSE from "@/api/biz/api_customerwarehouse";
import projectext0 from './projectext0';
import projectext1 from './projectext1';
import projectext2 from './projectext2';
import projectext3 from './projectext3';
import projectext4 from './projectext4';

export default {
  name: "projectEdit",
  components:{
    projectext0,
    projectext1,
    projectext2,
    projectext3,
    projectext4,
  },
  data() {
    return {
      customerId: undefined,
      projectId: undefined,
      projectExtId: undefined,

      editProjectInfo: {},
      editProjectExtInfo: {},

      //
      customerInfo: {},

      //tab
      selectedTab: "first",
      showTabSecond: false,
      customerWarehouseList: undefined,
      customerType: {
        0: '潜在', 1: '合作', 2: '流失'
      },

      //rules

      editProjectInfoRules: {
        name: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "长度在 2 到 50 个字符",
            trigger: "blur",
          },
        ],
       
        type: [{ required: true, message: "请选择项目类型", trigger: "blur" }],
        lawstype: [
          { required: true, message: "请选择项目法规", trigger: "blur" },
        ],
        memo: [
          {
            min: 0,
            max: 500,
            message: "长度要小于 500 个字符",
            trigger: "blur",
          },
        ],
      },
      
      customerTagList: [],
      customerCheckedTags: [],
      auth: {}
    };
  },
  
  // 初始化加载
  mounted() {
    this.init();
  },
  computed: {
    computeTitle() {
      return this.projectId ? "编辑项目" : "新建项目";
    },
  },
  methods: {
    init() {
      this.init_base();
      this.auth=this.$getAuth('/ProjectManage');
      this.initPageData();
    },

    // 加载初始数据支持
    init_base(){
      COMM.getObjType({type: 'customertag'}).then((res) => {
        this.customerTagList = res;
      });
      WAREHOUSE.listByCustomerId({custid: this.$route.query.customerId}).then((res) => {
        this.customerWarehouseList = res;
      });
    },

    // 加载页面数据
    initPageData() {
      this.customerId = this.$route.query.customerId;
      this.projectId = this.$route.query.projectId;

      if (this.projectId && this.projectId > 0) {
        let params = { id: this.$route.query.projectId };
        PROJECT.get(params).then((res) => {
          if (200 == res.code) {
            this.editProjectInfo = res?.data;
            this.customerId = this.editProjectInfo?.custid;
            this.customerInfo = this.editProjectInfo?.customer;
            this.showTabSecond = true;
            if(res?.data?.projectExt){
              this.editProjectExtInfo=res.data.projectExt;
              this.projectExtId=res.data.projectExt.id;
            }

          } else {
            this.$message.error(res?.msg);
          }
        });
      }

      if (this.customerId && this.customerId > 0) {
        let params = { id: this.customerId };
        CUST.get(params).then((res) => {
          if (200 == res.code) {
            this.customerInfo = res?.data;
            this.buildCustomerTags(this.customerInfo);
          } else {
            this.$message.error(res?.msg);
          }
        });
      } 
      
    },

    buildCustomerTags(customerInfo) {
      if (customerInfo == null) {
        return;
      }

      let tag = customerInfo.tag;
      for (const index in this.customerTagList) {
        let customerTag = this.customerTagList[index];
        if (tag.indexOf(customerTag['value'] + ',') >= 0) {
          this.customerCheckedTags.push(customerTag);
        }
      }
    },

    handler_saveproject() {
      this.$refs["editProjectInfo"].validate((valid) => {
        if (valid) {
          let params = {
            id: this.editProjectInfo?.id,
            custid: this.customerId,
            type: this.editProjectInfo.type,
            lawstype: this.editProjectInfo.lawstype,
            memo: this.editProjectInfo.memo,
          };
          this.editProjectInfo.custname = this.customerInfo.name;
          this.editProjectInfo.custid = this.customerId;
          PROJECT.info(this.editProjectInfo).then((res) => {
            if (200 == res.code) {
              this.$message.success("操作成功！");
              this.projectId = res.data?.id;
              this.showTabSecond = true;
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    handler_saveprojectext() {
      if (!this.projectId) {
        this.$message.error("缺少项目");
        return false;
      }
      this.editProjectExtInfo.pmid = this.projectId;
      if (this.projectExtId) {
        this.editProjectExtInfo.id = this.projectExtId;
      }
      //this.$refs["editProjectExtInfo"].validate((valid) => {
      //  if (valid) {
          PROJECTEXT.info(this.editProjectExtInfo).then((res) => {
            if (200 == res.code) {
              this.$message.success("操作成功！");
              this.projectExtId = res.data?.id;
            } else {
              this.$message.error(res.msg);
            }
          });
      // } else {
      //    return false;
      //  }
      //});
    },
    handler_tabclick() {
      if (this.selectedTab == "second" && this.projectExtId) {
        let params = { pmid: this.projectId };
        PROJECTEXT.get(params).then((res) => {
          if (200 == res.code) {
            this.projectExtId = res.data?.id;
            this.editProjectExtInfo = res.data;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.center {
  text-align: center;
}
.row {
  line-height: 30px;
  margin-bottom: 10px;
}
.customer-box {
  margin: 10px 0;
}
.tabs-box {
  margin: 30px 50px;
}
.el-form-item .el-form-item {
  margin-bottom: 10px;
}
</style>