<template>
  <div>
    <p class="title">冷藏箱参数</p>
    <el-form
      :model="editProjectExtInfo"
      ref="editProjectExtInfo"
      :rules="editProjectExtInfoRules"
      class="demo-ruleForm"
    >
      <el-row>
        <el-col :span="18">
          <el-form-item label="名称" label-width="150px" prop="identifier">
            <el-input v-model="editProjectExtInfo.identifier">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="冷藏箱容积" label-width="150px" prop="volume">
            <el-input v-model="editProjectExtInfo.volume">
              <template #append>升（L）</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item
            label="品牌/型号"
            label-width="150px"
            prop="brand"
          >
           <el-input v-model="editProjectExtInfo.brand">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="外部尺寸" label-width="150px" prop="outdimension">
            <el-input v-model="editProjectExtInfo.outdimension">
              <template #append>mm</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="内部尺寸" label-width="150px" prop="innerdimension">
            <el-input v-model="editProjectExtInfo.innerdimension">
              <template #append>mm</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="净重/毛重" label-width="150px" prop="weight">
            <el-input v-model="editProjectExtInfo.weight">
              <template #append>kg</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="壁厚" label-width="150px" prop="wallthickness">
            <el-input v-model="editProjectExtInfo.wallthickness">
              <template #append>mm</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      
      <el-row>
        <el-col :span="18">
          <el-form-item label="监测系统探头数量" label-width="150px" prop="humiditymonitorqty">
            <el-input v-model="editProjectExtInfo.humiditymonitorqty">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="厂家" label-width="150px" prop="manufacturer">
            <el-input v-model="editProjectExtInfo.manufacturer">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="验证对象温度控制范围目标"
            label-width="150px"
            prop=""
          >
            <el-col :span="10">
              <el-form-item
                label="最低温度"
                label-width="100px"
                prop="verifytemperaturemin"
              >
                <el-input v-model="editProjectExtInfo.verifytemperaturemin">
                  <template #append>℃</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="10">
              <el-form-item
                label="最高温度"
                label-width="100px"
                prop="verifytemperaturemax"
              >
                <el-input v-model="editProjectExtInfo.verifytemperaturemax">
                  <template #append>℃</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="监测探头编号" label-width="150px" prop="monitorsn">
            <el-input v-model="editProjectExtInfo.monitorsn">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="外部环境测点编号" label-width="150px" prop="outsideenvmonitorsn">
            <el-input v-model="editProjectExtInfo.outsideenvmonitorsn">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- end ext-->
    </el-form>
    <div slot="footer" class="txt-center">
      <el-button type="primary" @click="handler_saveprojectext()"
        >确定</el-button
      >
    </div>
  </div>
</template>

<script>

export default {
  name: "projectext3",
  props: {
    editProjectExtInfo: {
      type: Object,
      required: true,
    },
    
  },
 
  data() {
    return {
      editProjectExtInfoRules: {
        brand: [
          { required: true, message: "品牌及型号是必需的", trigger: "blur" },
        ],
        volume: [{ required: true, message: "冷藏箱容积是必需的", trigger: "blur" }],
        identifier: [{ required: true, message: "编号是必需的", trigger: "blur" }],
        outdimension: [
          { required: true, message: "外部尺寸是必需的", trigger: "blur" },
        ],
        innerdimension: [
          { required: true, message: "内部尺寸是必需的", trigger: "blur" },
        ],
        weight: [
          { required: true, message: "重量是必需的", trigger: "blur" },
        ],
        wallthickness: [
          { required: true, message: "壁厚是必需的", trigger: "blur" },
        ],
        // humiditymonitorqty: [
        //   { required: true, message: "监测系统探头数量是必需的", trigger: "blur" },
        // ],
        // manufacturer: [
        //   { required: true, message: "厂家是必需的", trigger: "blur" },
        // ],
        verifytemperaturemin: [
          { required: true, message: "验证对象温度控制范围最低温度是必需的", trigger: "blur" },
        ],
        verifytemperaturemax: [
          { required: true, message: "验证对象温度控制范围最高温度是必需的", trigger: "blur" },
        ],
        // monitorsn: [
        //   { required: true, message: "监测探头编号是必需的", trigger: "blur" },
        // ],
        outsideenvmonitorsn: [
          { required: true, message: "外部环境测点编号是必需的", trigger: "blur" },
        ],
        
      },
    };
  },
  methods: {
    handler_saveprojectext() {
      this.$refs["editProjectExtInfo"].validate((valid) => {
        if (valid) {
          this.$emit('saveprojectext')
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.title{
  text-align: center;
  margin:10px;
}
</style>