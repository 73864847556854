<template>
  <div>
    <p class="title">温湿度监控系统参数</p>
    <el-form
      :model="editProjectExtInfo"
      ref="editProjectExtInfo"
      :rules="editProjectExtInfoRules"
      class="demo-ruleForm"
    >
      <el-row>
        <el-col :span="18">
          <el-form-item label="温湿度变送器数量" label-width="150px" prop="temphumiditytransmitterqty">
            <el-input v-model="editProjectExtInfo.temphumiditytransmitterqty">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="温湿度变送器供应商" label-width="150px" prop="temphumiditytransmittersupplier">
            <el-input v-model="editProjectExtInfo.temphumiditytransmittersupplier">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item
            label="声光报警器平台数量"
            label-width="150px"
            prop="audiblevisualalarmqty"
          >
           <el-input v-model="editProjectExtInfo.audiblevisualalarmqty">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="声光报警器平台供应商" label-width="150px" prop="audilevisualsupplier">
            <el-input v-model="editProjectExtInfo.audilevisualsupplier">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="断电声光报警器平台数量" label-width="150px" prop="poweroffaudiblevisualqty">
            <el-input v-model="editProjectExtInfo.poweroffaudiblevisualqty">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="断电声光报警器平台供应商" label-width="150px" prop="poweroffaudiblevisualsupplier">
            <el-input v-model="editProjectExtInfo.poweroffaudiblevisualsupplier">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="短信报警平台数量" label-width="150px" prop="smsalarmqty">
            <el-input v-model="editProjectExtInfo.smsalarmqty">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      
      <el-row>
        <el-col :span="18">
          <el-form-item label="短信报警平台供应商" label-width="150px" prop="smsalarmsupplier">
            <el-input v-model="editProjectExtInfo.smsalarmsupplier">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="自动监测系统软件服务器端数量" label-width="150px" prop="automaticmonitorserverqty">
            <el-input v-model="editProjectExtInfo.automaticmonitorserverqty">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="自动监测系统软件服务器端供应商" label-width="150px" prop="automaticmonitorserversupplier">
            <el-input v-model="editProjectExtInfo.automaticmonitorserversupplier">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="自动监测系统软件客户端数量" label-width="150px" prop="automaticmonitorclientqty">
            <el-input v-model="editProjectExtInfo.automaticmonitorclientqty">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="自动监测系统软件客户端供应商" label-width="150px" prop="automaticmonitorclientsupplier">
            <el-input v-model="editProjectExtInfo.automaticmonitorclientsupplier">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="系统抽取温湿度测点的SN号" label-width="150px" prop="temperaturehumiditypointsn">
            <el-input v-model="editProjectExtInfo.temperaturehumiditypointsn">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
     
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="常温库温度范围"
            label-width="150px"
            prop=""
          >
            <el-col :span="10">
              <el-form-item
                label="最低温度"
                label-width="100px"
                prop="normalstoragetemperaturemin"
              >
                <el-input v-model="editProjectExtInfo.normalstoragetemperaturemin">
                  <template #append>℃</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="10">
              <el-form-item
                label="最高温度"
                label-width="100px"
                prop="normalstoragetemperaturemax"
              >
                <el-input v-model="editProjectExtInfo.normalstoragetemperaturemax">
                  <template #append>℃</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
      <!--min max-->
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="阴凉库温度范围"
            label-width="150px"
            prop=""
          >
            <el-col :span="10">
              <el-form-item
                label="最低温度"
                label-width="100px"
                prop="coolstoragetemperaturemin"
              >
                <el-input v-model="editProjectExtInfo.coolstoragetemperaturemin">
                  <template #append>℃</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="10">
              <el-form-item
                label="最高温度"
                label-width="100px"
                prop="coolstoragetemperaturemax"
              >
                <el-input v-model="editProjectExtInfo.coolstoragetemperaturemax">
                  <template #append>℃</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="常温库湿度范围"
            label-width="150px"
            prop=""
          >
            <el-col :span="10">
              <el-form-item
                label="最低温度"
                label-width="100px"
                prop="normalstoragehumiditymin"
              >
                <el-input v-model="editProjectExtInfo.normalstoragehumiditymin">
                  <template #append>℃</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="10">
              <el-form-item
                label="最高温度"
                label-width="100px"
                prop="normalstoragehumiditymax"
              >
                <el-input v-model="editProjectExtInfo.normalstoragehumiditymax">
                  <template #append>℃</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="阴凉库湿度范围"
            label-width="150px"
            prop=""
          >
            <el-col :span="10">
              <el-form-item
                label="最低温度"
                label-width="100px"
                prop="coolstoragehumiditymin"
              >
                <el-input v-model="editProjectExtInfo.coolstoragehumiditymin">
                  <template #append>℃</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="10">
              <el-form-item
                label="最高温度"
                label-width="100px"
                prop="normalstoragetemperaturemax"
              >
                <el-input v-model="editProjectExtInfo.coolstoragehumiditymax">
                  <template #append>℃</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
     
      <!-- end ext-->
    </el-form>
    <div slot="footer" class="txt-center">
      <el-button type="primary" @click="handler_saveprojectext()"
        >确定</el-button
      >
    </div>
  </div>
</template>

<script>

export default {
  name: "projectext4",
  props: {
    editProjectExtInfo: {
      type: Object,
      required: true,
    },
    
  },
 
  data() {
    return {
      editProjectExtInfoRules: {
        temphumiditytransmitterqty: [
          { required: true, message: "温湿度变送器数量是必需的", trigger: "blur" },
        ],
        temphumiditytransmittersupplier: [{ required: true, message: "温湿度变送器供应商是必需的", trigger: "blur" }],
        audiblevisualalarmqty: [{ required: true, message: "声光报警器平台数量是必需的", trigger: "blur" }],
        audilevisualsupplier: [
          { required: true, message: "声光报警器平台供应商是必需的", trigger: "blur" },
        ],
        poweroffaudiblevisualqty: [
          { required: true, message: "断电声光报警器平台数量是必需的", trigger: "blur" },
        ],
        poweroffaudiblevisualsupplier: [
          { required: true, message: "断电声光报警器平台供应商是必需的", trigger: "blur" },
        ],
        smsalarmqty: [
          { required: true, message: "短信报警平台数量是必需的", trigger: "blur" },
        ],
        smsalarmsupplier: [
          { required: true, message: "短信报警平台平台供应商是必需的", trigger: "blur" },
        ],
        automaticmonitorserverqty: [
          { required: true, message: "自动监测系统软件服务器端数量是必需的", trigger: "blur" },
        ],
        automaticmonitorserversupplier: [
          { required: true, message: "自动监测系统软件服务器端供应商是必需的", trigger: "blur" },
        ],
        automaticmonitorclientqty: [
          { required: true, message: "自动监测系统软件客户端数量是必需的", trigger: "blur" },
        ],
        automaticmonitorclientsupplier: [
          { required: true, message: "自动监测系统软件客户端供应商是必需的", trigger: "blur" },
        ],
        // temperaturehumiditypointsn: [
        //   { required: true, message: "系统抽取温湿度测点的SN号是必需的", trigger: "blur" },
        // ],
        normalstoragetemperaturemin: [
          { required: true, message: "常温库温度最低温度是必需的", trigger: "blur" },
        ],
        normalstoragetemperaturemax: [
          { required: true, message: "常温库温度最高温度是必需的", trigger: "blur" },
        ],
        coolstoragetemperaturemin: [
          { required: true, message: "阴凉库温度最低温度是必需的", trigger: "blur" },
        ],
        coolstoragetemperaturemax: [
          { required: true, message: "阴凉库温度最高温度是必需的", trigger: "blur" },
        ],
        normalstoragehumiditymin: [
          { required: true, message: "常温库湿度最低温度是必需的", trigger: "blur" },
        ],
        normalstoragehumiditymax: [
          { required: true, message: "常温库湿度最高温度是必需的", trigger: "blur" },
        ],
        coolstoragehumiditymin: [
          { required: true, message: "阴凉库湿度最低温度是必需的", trigger: "blur" },
        ],
        coolstoragehumiditymax: [
          { required: true, message: "阴凉库湿度最高温度是必需的", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handler_saveprojectext() {
      this.$refs["editProjectExtInfo"].validate((valid) => {
        if (valid) {
          this.$emit('saveprojectext')
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.title{
  text-align: center;
  margin:10px;
}
</style>